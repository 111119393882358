// @flow
import type { BookType, DipseaContentType, TagType } from '../flowTypes'
import { getLibrary } from '../services/dipseaApi'
import { cloneDeep } from 'lodash'
import { sortSlugs } from './helpers'
import type { TagInfoType } from './Tag'

export async function getBookSlugsAsync (): Promise<string[]> {
  // $FlowFixMe
  const library = await getLibrary()
  const slugs = Object.keys(library?.slugs?.books)

  return slugs.filter((slug: string): boolean => library?.books?.[library?.slugs?.books[slug]]?.publishedAt && library.books[library?.slugs?.books[slug]].publishedAt < Date.now())
}

export async function getBookBySlugAsync (slug: string): Promise<BookType> {
  const library = await getLibrary()
  const bookId = library?.slugs?.books[slug]
  const book = cloneDeep(library?.books[bookId] || {})
  return book
}

export async function getAllBooksAsync (): Promise<BookType[]> {
  const library = await getLibrary()
  // $FlowFixMe
  const booksIds: string[] = Object.keys(library.books)
  return booksIds.filter((key: string): boolean => !!library.books[key]).sort((a: string, b: string): number => library.books[a].index - library.books[b].index).map((key: string): BookType => library.books[key])
}

export type BookInfoType = {
  title: string,
  id: string,
  slug: string,
  description: string,
  shortDescription: string,
  imageUrl: string,
  tags: TagInfoType[]
}

export function convertBookToBookInfo (book: BookType, library: DipseaContentType): BookInfoType {
  const tags = getTagInfoFromBook(book, library)
  return {
    title: book.title,
    id: book.id,
    slug: '/books/' + sortSlugs(book.slugs)[0],
    description: book.description,
    shortDescription: book.shortDescription,
    imageUrl: book['image-500'],
    tags
  }
}

export function getTagInfoFromBook (book: BookType, library: DipseaContentType): TagInfoType[] {
  const tagIds = Object.keys(book.tagIds || {})

  return tagIds.sort((a: string, b: string): number => book.tagIds[a].index - book.tagIds[b].index).map((tagId: string): TagInfoType => {
    const tag: TagType = library.tags[tagId]
    const slug = sortSlugs(tag.slugs)[0]
    return {
      id: tagId,
      slug: `/tags/${slug}`,
      title: tag.name,
      imageUrl: tag.image_url || null,
      logLine: null,
      description: null
    }
  })
}

export async function getBookInfosAsync (): Promise<BookInfoType[]> {
  const [books, library] = await Promise.all([getAllBooksAsync(), getLibrary()])
  return books.map((book: BookType): BookInfoType => convertBookToBookInfo(book, library))
}

export async function getBooksListPropsAsync (): Promise<BookInfoType[]> {
  const library = await getLibrary()
  const booksIds: string[] = Object.keys(library.books || {}).sort((a: string, b: string): number => library.books[b].publishedAt - library.books[a].publishedAt)
  return booksIds.map((key: string): BookInfoType => (convertBookToBookInfo({ ...library.books[key], id: key }, library)))
}
export type ChapterInfoPropsType = {
  title: string,
  index: number,
  description: string
}
export type BookDetailPropsType = {
  title: string,
  id: string,
  slug: string,
  description: string,
  imageUrl: string,
  tags: TagInfoType[],
  chapters: ChapterInfoPropsType[]
}

export function getChaptersInfoFromBook (book: BookType): ChapterInfoPropsType[] {
  return Object.keys(book.chapterIds || {})
    .sort((a: string, b: string): number => book.chapterIds[a].index - book.chapterIds[b].index)
    .map((chapterId: string): ChapterInfoPropsType => {
      const chapter = book.chapterIds[chapterId]
      return {
        title: chapter.title,
        index: chapter.index,
        description: chapter.shortDescription
      }
    })
}
export type BookInfoPropsType = {
  title: string,
  id: string,
  slug: string,
  description: string,
  shortDescription: string,
  imageUrl: string
}
export function getBookInfoProps (bookId: string, library: DipseaContentType): BookInfoPropsType {
  const book = cloneDeep(library?.books[bookId] || {})
  return {
    title: book.title,
    id: bookId,
    slug: '/books/' + sortSlugs(book.slugs)[0],
    description: book.description,
    shortDescription: book.shortDescription,
    imageUrl: book['image-500']
  }
}
export async function getBookDetailPropsAsync (slug: string): Promise<BookDetailPropsType> {
  const library = await getLibrary()
  const bookId = library?.slugs?.books[slug]
  const book = cloneDeep(library?.books[bookId] || {})
  const tags = getTagInfoFromBook(book, library)
  return {
    title: book.title,
    id: bookId,
    slug: '/books/' + sortSlugs(book.slugs)[0],
    description: book.description,
    imageUrl: book['image-500'],
    tags,
    chapters: getChaptersInfoFromBook(book)
  }
}
