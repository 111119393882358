// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import Link from 'next/link'
import { QueensH5 } from '@components/shared/typography'
import { config } from 'react-awesome-styled-grid'

type PropsType = {
  cta?: {
    title: string,
    url: string
  } | null,
  title: string,
  description?: string | null,
  color?: string
}
export function HeaderSeeAll (props: PropsType): React.Node {
  return (
    <HeaderSeeAllContainer>
      <TitleAndDescriptionContainer>
        <HeaderText>{props.title}</HeaderText>
        {props.cta && <LinkSeeAll href={props.cta.url} passHref>{props.cta.title}</LinkSeeAll>}
      </TitleAndDescriptionContainer>
      {props.description && <DescriptionContainer color={colors.white70}>{props.description}</DescriptionContainer>}
    </HeaderSeeAllContainer>
  )
}

const LinkSeeAll = styled(Link)`
    flex-shrink: 0;
`
const DescriptionContainer = styled.div`
    font-family: "Good Sans Regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 130.769% */
    letter-spacing: -0.3px;
    margin-top: 10px;
    padding-right: 8px;
    ${(props: any): string => {
      return props.color ? `color: ${props.color};` : `color: ${colors.white};`
    }};
  
    ${(props: any): string => config(props).media.xs`
    `}
    ${(props: any): string => config(props).media.sm`
      margin-top: 15px;
    `}
    ${(props: any): string => config(props).media.lg`
      font-size: 16px;
    `}
    ${(props: any): string => config(props).media.xl`
    `}
`
const TitleAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  ${(props: any): string => config(props).media.xs`
  `}
  ${(props: any): string => config(props).media.sm`
  `}
  ${(props: any): string => config(props).media.md`
  `}
  ${(props: any): string => config(props).media.lg`
  `}
  ${(props: any): string => config(props).media.xl`
  `}
`
const HeaderSeeAllContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;
    ${(props: any): string => config(props).media.sm`
      margin-bottom: 22px;
    `}
    ${(props: any): string => config(props).media.lg`
      margin-bottom: 32px;
    `}
    
    a {
        font-family: "Good Sans Bold";
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-block: 0;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        color: ${colors.tintOrange};
        text-decoration: underline;
        font-size: 11px;
        @media (min-width: 768px) {
            font-size: 14px;
        }
    }
`
const HeaderText = styled.h5`
  ${QueensH5};
  ${(props: any): string => {
      return props.color ? `color: ${props.color};` : `color: ${colors.white};`
  }};
`
