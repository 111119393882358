// @flow
import * as React from 'react'
import styled from 'styled-components'
import type { TagInfoType } from '../../models/Tag'
import { colors } from '@components/shared'
import { config } from 'react-awesome-styled-grid'
import Link from 'next/link'

export type PropsType = {
  tags: any[],
  displayTagCount?: number,
  maxLines?: number,
  clickable: boolean
}

export function TrackTags (props: PropsType): React.Node {
  const { tags, displayTagCount, clickable } = props
  const VOOutput = (text: string): string => text.replace('+', ' and ')

  return (
    <TagWrapper className={'track-tags'}>
      <TagTextContainer maxLines={props.maxLines}>
        {tags.map((tag: TagInfoType, idx: number): React.Node => {
          if (displayTagCount && idx < displayTagCount) {
            if (clickable) {
              return <span key={idx}><Link href={tag.slug} passHref={true} aria-label={VOOutput(tag.title)}>{tag.title}</Link>{(idx + 1) < displayTagCount && (idx + 1 < tags.length) && <span aria-hidden={true}>, </span>}</span>
            }
            return <span key={idx} aria-label={VOOutput(tag.title)}>
                {tag.title}
              {(idx + 1) < displayTagCount && (idx + 1 < tags.length) && <span aria-hidden={true}>, </span>}
              </span>
          }
          return null
        })}
      </TagTextContainer>
    </TagWrapper>
  )
}

TrackTags.defaultProps = {
  displayTagCount: 6,
  clickable: false
}

export default TrackTags
export const TagText = styled.div`
  color: ${colors.white70};
  font-family: "Good Sans Light";
  font-style: normal;
  font-weight: normal;
  margin: 0;
  font-size: 11px;
    line-height: 150%;

    ${(props: any): string => config(props).media.md`
    font-size: 14px;
  `}
    ${(props: any): string => config(props).media.lg`
    font-size: 16px;
  `}
  a {
      text-decoration: underline;
      color: ${colors.white70};
  }
`

const TagWrapper = styled.div`
  display: -webkit-box;
  text-align: left;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`
const TagTextContainer = styled(TagText)`
    a {
        text-decoration: none;
    }
    ${(props: any): string => config(props).media.sm`
      a {
          text-decoration: underline;
      }
    `}
  ${(props: any): any => {
  if (props.maxLines) {
    return `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${props.maxLines};
        -webkit-box-orient: vertical;
      `
  };
}};
`
